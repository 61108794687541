<script>
  import axios from "axios";
  export default {
    name: "MyNavbar",
    data(){
      return{
        categories:'',
      }
    },
    created() {
      this.getAllPublishedCategories();
    },

    computed:{
      totalCartItem()
      {
        return this.$store.getters.getProducts.length;
      },
      customer()
      {
        return this.$store.getters.getCustomer;
      },
      products()
      {
        return this.$store.getters.getProducts;
      },
      subTotal()
      {
        return this.$store.getters.getSubTotal;
      },
      taxTotal()
      {
        return this.$store.getters.getTaxTotal;
      },
      shippingTotal()
      {
        return this.$store.getters.getShippingTotal;
      },
      grandTotal()
      {
        return this.$store.getters.getGrandTotal;
      }
    },
    methods:{
      getAllPublishedCategories()
      {
        axios.get('https://shopgrid.sabitech.info/api/all-published-category').then((response)=>{
          this.categories = response.data;
          //console.log(response.data)
        });
      },

      deleteCartProduct(id)
      {
        var check = confirm('Are you sure?')
        if (check)
        {
          this.$store.commit('deleteCartProduct', {id: id});
          this.$store.commit('createSubTotal');
          this.$store.commit('createShippingTotal');
          this.$store.commit('createGrandTotal');
        }
      },

      logout()
      {
        this.$store.commit('removeCustomer');
        this.$router.push('/dashboard')
      }
    }
  }

</script>

<template>
  <header class="header navbar-area">

    <div class="topbar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="top-left">
              <ul class="menu-top-link">
                <li>
                  <div class="select-position">
                    <select id="select4">
                      <option value="0" selected>$ USD</option>
                      <option value="1">€ EURO</option>
                      <option value="2">$ CAD</option>
                      <option value="3">₹ INR</option>
                      <option value="4">¥ CNY</option>
                      <option value="5">৳ BDT</option>
                    </select>
                  </div>
                </li>
                <li>
                  <div class="select-position">
                    <select id="select5">
                      <option value="0" selected>English</option>
                      <option value="1">Español</option>
                      <option value="2">Filipino</option>
                      <option value="3">Français</option>
                      <option value="4">العربية</option>
                      <option value="5">हिन्दी</option>
                      <option value="6">বাংলা</option>
                    </select>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="top-middle">
              <ul class="useful-links">
                <li><a href="index.html">Home</a></li>
                <li><a href="about-us.html">About Us</a></li>
                <li><a href="contact.html">Contact Us</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="top-end" v-if="customer.id">
              <div class="user">
                <i class="lni lni-user"></i>
                Hello{{customer.name}}
              </div>
              <ul class="user-login">
                <li>
                  <a href="">My Dashboard</a>
                </li>
                <li>
                  <a href="" @click="logout">Logout</a>
                </li>
              </ul>
            </div>
            <div class="top-end" v-else>
              <ul class="user-login">
                <li>
                  <a href="">Sign In</a>
                </li>
                <li>
                  <a href="">Register</a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="header-middle">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3 col-md-3 col-7">

            <router-link :to="{name:'home'}" class="navbar-brand">
              <img src="/assets/images/logo/logo.svg" alt="Logo">
            </router-link>

          </div>
          <div class="col-lg-5 col-md-7 d-xs-none">

            <div class="main-menu-search">

              <div class="navbar-search search-style-5">
                <div class="search-select">
                  <div class="select-position">
                    <select id="select1">
                      <option selected>All</option>
                      <option value="1">option 01</option>
                      <option value="2">option 02</option>
                      <option value="3">option 03</option>
                      <option value="4">option 04</option>
                      <option value="5">option 05</option>
                    </select>
                  </div>
                </div>
                <div class="search-input">
                  <input type="text" placeholder="Search">
                </div>
                <div class="search-btn">
                  <button><i class="lni lni-search-alt"></i></button>
                </div>
              </div>

            </div>

          </div>
          <div class="col-lg-4 col-md-2 col-5">
            <div class="middle-right-area">
              <div class="nav-hotline">
                <i class="lni lni-phone"></i>
                <h3>Hotline:
                  <span>(+100) 123 456 7890</span>
                </h3>
              </div>
              <div class="navbar-cart">
                <div class="wishlist">
                  <a href="javascript:void(0)">
                    <i class="lni lni-heart"></i>
                    <span class="total-items">0</span>
                  </a>
                </div>
                <div class="cart-items">
                  <a href="javascript:void(0)" class="main-btn">
                    <i class="lni lni-cart"></i>
                    <span class="total-items">{{totalCartItem}}</span>
                  </a>

                  <div class="shopping-item" v-for="(product, key) in products" :key="key">
                    <div class="dropdown-cart-header">
                      <span>{{totalCartItem}}</span>
                      <router-link :to="{name: 'cart'}">View Cart</router-link>
                    </div>
                    <ul class="shopping-list" v-for="(product, key) in products" :key="key">
                      <li>
                        <a href="javascript:void(0)" class="remove" title="Remove this item" @click="deleteCartProduct(product.id)"><i class="lni lni-close"></i></a>
                        <div class="cart-img-head">
                          <a href=""><img :src="product.image" alt="#"></a>
                        </div>
                        <div class="content">
                          <h4><a href="">{{product.name}} </a></h4>
                          <p class="value">({{product.price}} * {{product.qty}})</p>
                          <p class="price">Tk. {{product.total}}</p>
                        </div>
                      </li>
                    </ul>
                    <div class="bottom">
                      <div class="total">
                        <span>Total</span>
                        <p class="price">Tk.{{subTotal}}</p>
                      </div>
                      <div class="button">
                        <router-link :to="{name:'checkout'}" class="btn" >Checkout</router-link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-6 col-12">
          <div class="nav-inner">
            <div class="mega-category-menu">
              <span class="cat-button"><i class="lni lni-menu"></i>All Categories</span>
              <ul class="sub-category">
                <li v-for="(category, key) in categories" :key="key">
                  <router-link :to="{name: 'category', params:{id:category.id}}">{{category.name}} <i class="lni lni-chevron-right" v-if="category.sub_category.length>0"></i></router-link>
                  <ul class="inner-sub-category" v-if="category.sub_category.length >0">
                    <li v-for="(subCategory, key1) in category.sub_category" :key="key1">
                      <a href="#">{{subCategory.name}}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>


            <nav class="navbar navbar-expand-lg">
              <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
                <span class="toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul id="nav" class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <a href="index.html" class="active" aria-label="Toggle navigation">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse" data-bs-target="#submenu-1-2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Pages</a>
                    <ul class="sub-menu collapse" id="submenu-1-2">
                      <li class="nav-item"><a href="about-us.html">About Us</a></li>
                      <li class="nav-item"><a href="faq.html">Faq</a></li>
                      <li class="nav-item"><a href="login.html">Login</a></li>
                      <li class="nav-item"><a href="register.html">Register</a></li>
                      <li class="nav-item"><a href="mail-success.html">Mail Success</a></li>
                      <li class="nav-item"><a href="404.html">404 Error</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse" data-bs-target="#submenu-1-3" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Shop</a>
                    <ul class="sub-menu collapse" id="submenu-1-3">
                      <li class="nav-item"><a href="product-grids.html">Shop Grid</a></li>
                      <li class="nav-item"><a href="product-list.html">Shop List</a></li>
                      <li class="nav-item"><a href="product-details.html">shop Single</a></li>
                      <li class="nav-item"><a href="cart.html">Cart</a></li>
                      <li class="nav-item"><a href="checkout.html">Checkout</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="dd-menu collapsed" href="javascript:void(0)" data-bs-toggle="collapse" data-bs-target="#submenu-1-4" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">Blog</a>
                    <ul class="sub-menu collapse" id="submenu-1-4">
                      <li class="nav-item"><a href="blog-grid-sidebar.html">Blog Grid Sidebar</a>
                      </li>
                      <li class="nav-item"><a href="blog-single.html">Blog Single</a></li>
                      <li class="nav-item"><a href="blog-single-sidebar.html">Blog Single
                        Sibebar</a></li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a href="contact.html" aria-label="Toggle navigation">Contact Us</a>
                  </li>
                </ul>
              </div>
            </nav>

          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">

          <div class="nav-social">
            <h5 class="title">Follow Us:</h5>
            <ul>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-facebook-filled"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-twitter-original"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-instagram"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="lni lni-skype"></i></a>
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>

  </header>
</template>

<style scoped>

</style>
<script setup>

</script>

<template>
  <div class="home-product-list">
    <section class="home-product-list section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12 custom-responsive-margin">
            <h4 class="list-title">Best Sellers</h4>

            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/01.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">GoPro Hero4 Silver</a>
                </h3>
                <span>$287.99</span>
              </div>
            </div>


            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/02.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">Puro Sound Labs BT2200</a>
                </h3>
                <span>$95.00</span>
              </div>
            </div>


            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/03.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">HP OfficeJet Pro 8710</a>
                </h3>
                <span>$120.00</span>
              </div>
            </div>

          </div>
          <div class="col-lg-4 col-md-4 col-12 custom-responsive-margin">
            <h4 class="list-title">New Arrivals</h4>

            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/04.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">iPhone X 256 GB Space Gray</a>
                </h3>
                <span>$1150.00</span>
              </div>
            </div>


            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/05.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">Canon EOS M50 Mirrorless Camera</a>
                </h3>
                <span>$950.00</span>
              </div>
            </div>


            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/06.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">Microsoft Xbox One S</a>
                </h3>
                <span>$298.00</span>
              </div>
            </div>

          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <h4 class="list-title">Top Rated</h4>

            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/07.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">Samsung Gear 360 VR Camera</a>
                </h3>
                <span>$68.00</span>
              </div>
            </div>


            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/08.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">Samsung Galaxy S9+ 64 GB</a>
                </h3>
                <span>$840.00</span>
              </div>
            </div>


            <div class="single-list">
              <div class="list-image">
                <a href="product-grids.html"><img src="assets/images/home-product-list/09.jpg" alt="#"></a>
              </div>
              <div class="list-info">
                <h3>
                  <a href="product-grids.html">Zeus Bluetooth Headphones</a>
                </h3>
                <span>$28.00</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
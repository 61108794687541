<template>
  <div class="home">
    <HeroArea/>
    <featured-category/>
    <trending-product/>
    <banner-area/>
    <SpecialOffer/>
    <HomeProductList/>
    <PopularBrand/>
    <OurLatestNews/>
    <ShippingInfo/>
  </div>
</template>

<script>
import HeroArea from "@/components/home/HeroArea.vue";
import FeaturedCategory from "@/components/home/FeaturedCategory.vue";
import TrendingProduct from "@/components/home/TrendingProduct.vue";
import BannerArea from "@/components/home/BannerArea.vue";
import SpecialOffer from "@/components/home/SpecialOffer.vue";
import HomeProductList from "@/components/home/HomeProductList.vue";
import PopularBrand from "@/components/home/PopularBrand.vue";
import OurLatestNews from "@/components/home/OurLatestNews.vue";
import ShippingInfo from "@/components/home/ShippingInfo.vue";
export default {
  name: 'HomeView',
  components: {
    SpecialOffer, HeroArea, FeaturedCategory, TrendingProduct, BannerArea, HomeProductList,PopularBrand,OurLatestNews,ShippingInfo}
}
</script>

<script>
  import axios from "axios";
  export default {
    name: "FeatureCategory",
    data(){
      return{
        categories : '',
      }
    },
    created() {
      this.getFeatureCategories();
    },
    methods: {
      getFeatureCategories()
      {
        axios.get('http://localhost/shopgrid-admin/public/api/all-published-category').then((response)=>{
          this.categories = response.data;
          //console.log(response.data)
        });
      }
    }
  }
</script>

<template>
  <div class="featured-categories">
    <section class="featured-categories section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h2>Featured Categories</h2>
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                suffered alteration in some form.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12" v-for="(category, key) in categories" :key="key">
            <div class="single-category">
              <h3 class="heading">{{category.name}}</h3>
              <ul>
                <li v-for="(subCategory, key1) in category.sub_category" :key="key1"><a href="#">{{subCategory.name}}</a></li>
              </ul>
              <div class="images">
<!--                <img src="assets/images/featured-categories/fetured-item-1.png" alt="#">-->
                <img :src="category.image" :alt="category.name" height="140">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
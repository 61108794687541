import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state(){
        return{
            products:[],
            subTotal: 0,
            taxTotal: 0,
            shippingTotal: 0,
            grandTotal: 0,
            customer:{id: '', name: ''}
        }
    },

    getters:{
        getProducts(state)
        {
            return state.products;
        },
        getSubTotal(state)
        {
            return state.subTotal;
        },
        getTaxTotal(state)
        {
            return state.taxTotal;
        },
        getShippingTotal(state)
        {
            return state.shippingTotal;
        },
        getGrandTotal(state)
        {
            return state.grandTotal;
        },
        getCustomer(state)
        {
            return state.customer;
        }
    },

    mutations:{
        addProduct(state, payload)
        {
            state.products.push(payload);
        },

        updateCartProduct(state, payload)
        {
            var product     = state.products.find(product => product.id === payload.id);
            product.qty     = payload.qty;
            product.total   = payload.qty * product.price;
        },

        deleteCartProduct(state, payload)
        {
            var productIndex = state.products.findIndex(product => product.id === payload.id);
            state.products.splice(productIndex, 1);
        },

        deleteTotalCartProduct(state)
        {
            state.products      = [];
            state.subTotal      = 0;
            state.taxTotal      = 0;
            state.shippingTotal = 0;
            state.grandTotal    = 0;
        },

        createSubTotal(state)
        {
            var total = 0;
            for (var i = 0; i < state.products.length; i++)
            {
                total += state.products[i].total;
            }
            state.subTotal = total;
        },

        createTaxTotal(state)
        {
            state.taxTotal =  Math.round(((state.subTotal*15)/100));
        },

        createShippingTotal(state)
        {
            state.shippingTotal = 100;
        },

        createGrandTotal(state)
        {
            state.grandTotal = Number(state.subTotal) + Number (state.taxTotal) + Number (state.shippingTotal)
        },

        addCustomer(state, payload)
        {
            state.customer = payload;
        },

        removeCustomer(state)
        {
            state.customer = {};
        }
    },
    actions:{

    },
    modules:{

    },
    plugins: [createPersistedState()],
});
<script setup>

</script>

<template>
  <div class="special-offer">
    <section class="special-offer section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h2>Special Offer</h2>
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have
                suffered alteration in some form.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-12 col-12">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">

                <div class="single-product">
                  <div class="product-image">
                    <img src="assets/images/products/product-3.jpg" alt="#">
                    <div class="button">
                      <a href="product-details.html" class="btn"><i class="lni lni-cart"></i> Add to
                        Cart</a>
                    </div>
                  </div>
                  <div class="product-info">
                    <span class="category">Camera</span>
                    <h4 class="title">
                      <a href="product-grids.html">WiFi Security Camera</a>
                    </h4>
                    <ul class="review">
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><span>5.0 Review(s)</span></li>
                    </ul>
                    <div class="price">
                      <span>$399.00</span>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-lg-4 col-md-4 col-12">

                <div class="single-product">
                  <div class="product-image">
                    <img src="assets/images/products/product-8.jpg" alt="#">
                    <div class="button">
                      <a href="product-details.html" class="btn"><i class="lni lni-cart"></i> Add to
                        Cart</a>
                    </div>
                  </div>
                  <div class="product-info">
                    <span class="category">Laptop</span>
                    <h4 class="title">
                      <a href="product-grids.html">Apple MacBook Air</a>
                    </h4>
                    <ul class="review">
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><span>5.0 Review(s)</span></li>
                    </ul>
                    <div class="price">
                      <span>$899.00</span>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-lg-4 col-md-4 col-12">

                <div class="single-product">
                  <div class="product-image">
                    <img src="assets/images/products/product-6.jpg" alt="#">
                    <div class="button">
                      <a href="product-details.html" class="btn"><i class="lni lni-cart"></i> Add to
                        Cart</a>
                    </div>
                  </div>
                  <div class="product-info">
                    <span class="category">Speaker</span>
                    <h4 class="title">
                      <a href="product-grids.html">Bluetooth Speaker</a>
                    </h4>
                    <ul class="review">
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star-filled"></i></li>
                      <li><i class="lni lni-star"></i></li>
                      <li><span>4.0 Review(s)</span></li>
                    </ul>
                    <div class="price">
                      <span>$70.00</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="single-banner right" style="background-image:url('assets/images/banner/banner-3-bg.jpg');margin-top: 30px;">
              <div class="content">
                <h2>Samsung Notebook 9 </h2>
                <p>Lorem ipsum dolor sit amet, <br>eiusmod tempor
                  incididunt ut labore.</p>
                <div class="price">
                  <span>$590.00</span>
                </div>
                <div class="button">
                  <a href="product-grids.html" class="btn">Shop Now</a>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-4 col-md-12 col-12">
            <div class="offer-content">
              <div class="image">
                <img src="assets/images/offer/offer-image.jpg" alt="#">
                <span class="sale-tag">-50%</span>
              </div>
              <div class="text">
                <h2><a href="product-grids.html">Bluetooth Headphone</a></h2>
                <ul class="review">
                  <li><i class="lni lni-star-filled"></i></li>
                  <li><i class="lni lni-star-filled"></i></li>
                  <li><i class="lni lni-star-filled"></i></li>
                  <li><i class="lni lni-star-filled"></i></li>
                  <li><i class="lni lni-star-filled"></i></li>
                  <li><span>5.0 Review(s)</span></li>
                </ul>
                <div class="price">
                  <span>$200.00</span>
                  <span class="discount-price">$400.00</span>
                </div>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry incididunt ut
                  eiusmod tempor labores.</p>
              </div>
              <div class="box-head">
                <div class="box">
                  <h1 id="days">000</h1>
                  <h2 id="daystxt">Days</h2>
                </div>
                <div class="box">
                  <h1 id="hours">00</h1>
                  <h2 id="hourstxt">Hours</h2>
                </div>
                <div class="box">
                  <h1 id="minutes">00</h1>
                  <h2 id="minutestxt">Minutes</h2>
                </div>
                <div class="box">
                  <h1 id="seconds">00</h1>
                  <h2 id="secondstxt">Secondes</h2>
                </div>
              </div>
              <div style="background: rgb(204, 24, 24);" class="alert">
                <h1 style="padding: 50px 80px;color: white;">We are sorry, Event ended ! </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>